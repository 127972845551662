// Necessary if using App Router to ensure this file runs on the client
'use client'
import { nanoid } from '@/lib/utils'
import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'
import ENV_VARS from '@/lib/envVars'

export default function DatadogInit() {
  useEffect(() => {
    // Only initialize if we're in production
    if (
      ENV_VARS.NEXT_PUBLIC_VERCEL_ENV === 'production' &&
      ENV_VARS.NEXT_PUBLIC_DATADOG_APPLICATION_ID &&
      ENV_VARS.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
    ) {
      datadogRum.init({
        applicationId: ENV_VARS.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
        clientToken: ENV_VARS.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.com',
        service: 'cleanlab-chat',
        env: 'production',
        // Specify a version number to identify the deployed version of your application in Datadog
        version: '0.1.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        beforeSend: event => {
          if (event.type === 'error' && event.error?.source === 'network') {
            const status = (event.error as any).response?.status
            if (status >= 400 && status < 600) {
              const url = (event.error as any).response?.url || ''
              event.error.name = `${status} - ${url}`
              event.error.dd_fingerprint = `${status} - ${new URL(url).pathname}`
            }
          }
          return true
        }
      })
    }

    datadogRum.setUser({
      id: nanoid()
    })
  }, [])

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null
}
