'use client'

import {
  TooltipProvider,
  ThemeProvider
} from '@cleanlab/design-system/components'
import { MessagesStoreProvider } from '@/providers/messages-store-provider'
import { RagAppStoreProvider } from '@/providers/rag-app-store-provider'
import { TRPCProvider } from '@/trpc/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider as NextThemesProvider } from 'next-themes'

import type { ThemeProviderProps } from 'next-themes/dist/types'
import { SidebarProvider } from '@/lib/hooks/use-sidebar'
import DatadogInit from '@/app/DatadogInit'

export function Providers({ children, ...props }: ThemeProviderProps) {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <NextThemesProvider {...props}>
        <ThemeProvider>
          <TRPCProvider>
            <DatadogInit />
            <RagAppStoreProvider>
              <MessagesStoreProvider>
                <SidebarProvider>
                  <TooltipProvider>{children}</TooltipProvider>
                </SidebarProvider>
              </MessagesStoreProvider>
            </RagAppStoreProvider>
          </TRPCProvider>
        </ThemeProvider>
      </NextThemesProvider>
    </QueryClientProvider>
  )
}
