'use client'

// @ts-ignore
import { Suspense, use } from 'react'

// Copied from PR: https://github.com/mswjs/examples/pull/101

const mockingEnabledPromise =
  typeof window !== 'undefined' &&
  process.env.NEXT_PUBLIC_USE_MOCKED_API === 'true'
    ? import('@/mocks/browser').then(async ({ worker }) => {
        await worker.start({
          onUnhandledRequest(request, print) {
            if (
              ['_next', 'monitoring', 'datadoghq.com', '/trpc'].some(url =>
                request.url.includes(url)
              )
            ) {
              return
            }
            print.warning()
          }
        })
      })
    : Promise.resolve()

export function MSWProvider({
  children
}: Readonly<{
  children: React.ReactNode
}>) {
  // If MSW is enabled, we need to wait for the worker to start,
  // so we wrap the children in a Suspense boundary until it's ready.
  return (
    <Suspense fallback={null}>
      <MSWProviderWrapper>{children}</MSWProviderWrapper>
    </Suspense>
  )
}

function MSWProviderWrapper({
  children
}: Readonly<{
  children: React.ReactNode
}>) {
  use(mockingEnabledPromise)
  return children
}
