'use client'
import { PostHogProvider as PHProvider } from 'posthog-js/react'
import { useEffect } from 'react'
import AnalyticsPageView from '../../AnalyticsPageView'
import ENV_VARS from '@/lib/envVars'
import { analytics } from '@/services/analytics'

export function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    analytics.init()
  }, [])

  // Only wrap with PostHog provider in production
  if (ENV_VARS.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    return <>{children}</>
  }

  const analyticsInstance = analytics.getAnalyticsInstance()
  if (!analyticsInstance) {
    return <>{children}</>
  }

  return (
    <PHProvider client={analyticsInstance}>
      <AnalyticsPageView />
      {children}
    </PHProvider>
  )
}
